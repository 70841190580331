class AgencyModel {
    static postPayload(e) {
        return {
            name: e.name || "",
            logo: e.logo || [],
            website: e.website || "",
            personInChargeName: e.personInChargeName || "",
            personInChargeContact: e.personInChargeContact || ""
        };
    }

    static getToEditResponse(e) {
        return {
            name: e.name || "",
            logo: e.logo || [],
            website: e.website || "",
            personInChargeName: e.personInChargeName || "",
            personInChargeContact: e.personInChargeContact || ""
        };
    }
}

export default AgencyModel;
