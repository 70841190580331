<template>
  <div class="agency-profile animatedBox">
    <div class="container fluid">
      <fd-form @submit="submitForm" @error="validateError">
        <div class="card p-2 mb-2">
          <h4 class="mainTitle pt-3 pb-4">Edit Agency Profile</h4>
          <div class="row">
            <fd-input
              v-model="agencyData.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Agency Name"
              name="name"
              type="text"
              :validators="[
                validators.required,
                (val) => validators.minLength(val, 3)
              ]"
            >
            </fd-input>
            <fd-input
              v-model="agencyData.personInChargeName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Person In Charge"
              name="personInChargeName"
              type="text"
              :validators="[
                validators.required,
                (val) => validators.minLength(val, 3)
              ]"
            >
            </fd-input>
            <fd-input
              v-model="agencyData.personInChargeContact"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Person In Charge Contact"
              name="personInChargeContact"
              type="text"
              :validators="[validators.required, validators.phone]"
            >
            </fd-input>
            <fd-input
              v-model="agencyData.website"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Website"
              name="website"
              type="text"
              :validators="[validators.website]"
            >
            </fd-input>
            <div class="col-12 px-1 mb-2 main-photo">
              <label class="label">Agency Logo</label>
              <image-uploader
                v-model="agencyData.logo"
                :multiple="false"
                @error="
                  (error, imageName) => {
                    $reportError(error, 'Upload logo (Edit Agency Profile)');
                  }
                "
              >
              </image-uploader>
            </div>
          </div>
          <div class="text-right p-2">
            <button class="btn main">Update</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  phone,
  website
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import AgencyModel from "@/models/agency";

export default {
  name: "agency-profile-edit",
  components: {
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  data() {
    return {
      agencyData: {
        name: "",
        logo: [],
        website: "",
        personInChargeName: "",
        personInChargeContact: ""
      },
      validators: {
        required: required,
        minLength: minLength,
        phone: phone,
        website: website
      }
    };
  },
  mounted() {
    this.getAgencyProfile();
  },
  methods: {
    async getAgencyProfile() {
      this.$store.commit("setIsLoading", true);
      try {
        let data = await this.$store.dispatch("agencyProfile/getAgencyProfile");
        this.agencyData = AgencyModel.getToEditResponse(data);
        if (this.agencyData.logo == null) {
          this.agencyData.logo = [];
        }
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    validateError() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Please check if all inputs are valid."
      });
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        const request = await this.$store.dispatch(
          "agencyProfile/updateAgencyProfile",
          {
            id: this.$route.params.id,
            payload: AgencyModel.postPayload(this.agencyData)
          }
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Agency is updated successfully."
        });

        this.getAgencyProfile();
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error.response);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-edit {
  form {
    @extend %formDesign;
  }
}
</style>
